import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
  counter: 0,
  mensaje_notFound: null,
  documento_pdf: {
    facturaId: 0,
    nombreArchivo: "",
    pdf: "",
    xml: "",
    pdf33: "",
    xml33: ""
  },
  detalleFactura: {
    cfdi: {
      serie: '',
      folio: '',
      fecha: '',
      formaPago: '',
      condicionesDePago: null,
      granTotal: 0,
      total: 0,
      subTotal: 0,
      descuento: 0,
      moneda: '',
      tipoCambio: null,
      confirmacion: null,
      tipoCfdi: '',
      metodoPago: '',
      cargosNoFacturables: [
        {
          cargo: '',
          importe: 0
        }
      ],
      cfdiRelacionados: '',
      emisor: {
        rfc: '',
        nombre: '',
        regimenFiscal: ''
      },
      receptor: {
        rfc: '',
        nombre: '',
        numRegIdTrib: null,
        usoCFDI: null,
        residenciaFiscal: null,
        calle: '',
        noExterior: null,
        noInterior: null,
        colonia: '',
        municipio: null,
        estado: null,
        pais: null,
        codigoPostal: '',
        email: null,
        regimenFiscal: '',
        domicilioFiscal: ''
      },
      conceptos: [
        {
          cantidad: 1,
          claveUnidad: '',
          unidad: null,
          noIdentificacion: null,
          descripcion: '',
          valorUnitario: 0,
          importe: 0,
          descuento: 0,
          claveProdServ: '',
          numCuentaPredial: null,
          objetoImp: null,
          impuestos: {
            traslados: [
              {
                base: 0,
                impuesto: '',
                tipoFactor: '',
                tasaoCuota: 0,
                importe: 0
              }
            ],
            retenciones: null
          }
        }
      ],
      impuestos: {
        totalImpuestosRetenidos: null,
        totalImpuestosTrasladados: 0,
        retenciones: [],
        traslado: [
          {
            impuesto: '',
            tipoFactor: '',
            tasaoCuota: 0,
            importe: 0
          }
        ]
      },
      hospedaje: [],
      alimentos: [
        {
          cheque: '',
          fechaCheque: '',
          identificadorTicket: ''
        }
      ],
      impuestosLocales: {
        totalImpuestosRetenidos: 0,
        totalImpuestosTrasladados: 0,
        retencionesLocales: [],
        trasladoLocales: []
      },
      informacionFacto: {
        identificadorIntegracion: '',
        integracion: null,
        foliosFacto: false
      },
      exportacion: '',
      lugarExpedicion: ''
    },
    ticket: [
      {
        id: 0,
        identificadorIntegracionId: 0,
        estatus: '',
        facturable: true,
        factura: {
          id: 0,
          fechaEmision: '',
          fechaTimbrado: '',
          serie: '',
          folio: '',
          uuid: '',
          rfcReceptor: '',
          estatus: ''
        }
      }
    ],
    configuracionGeneral: {
      nivelBusqueda: '',
      serieFactura: {
        serie: '',
        folioFacto: false
      },
      serieNotaCredito: {
        serie: '',
        folioFacto: false
      },
      integracion: '',
      identificador: '',
      centroConsumoId: 0,
      sucursalId: 0,
      emisorId: 0,
      corporativoId: 0,
      configuracionPortalId: null
    }
  },
  configInicial: {
    logo: '',
    fondo: '',
    transaccionEjemplo: null,
    colores: [],
    leyendas: [],
    configuracionGeneral: {
      nivelBusqueda: "",
      serieFactura: {
        serie: "",
        folioFacto: null
      },
      serieNotaCredito: {
        serie: "",
        folioFacto: null
      },
      integracion: "",
      identificador: "",
      centroConsumoId: null,
      sucursalId: null,
      emisorId: null,
      corporativoId: null,
      configuracionPortalId: null
    },
    configuracionConcepto: null,
  },
  url_origen: '',
  preview_pdf: ''
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
