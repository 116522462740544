import React, { Fragment } from 'react';
import './App.css';
import AppMain from './Paginas';
// import './assets/styles/bulma.min.css';
import { withRouter } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Fragment>
        <AppMain />
      </Fragment>
    </div>
  );
}
export default withRouter(App);
