import React from 'react';
import './loading.css';
import logo_nt from './../assets/logo_transaprente.png';

export default function Loading(props) {
    return (
        <div className="loading-background">
            <div className="sbl-puzzle">
                <div>
                </div>
                <div>
                </div>
                <img className="imagen" src={logo_nt} alt="NT" />
            </div>
        </div>
    );
}
