export const formatoMoneda = (valor) => {
    let enteros = String(valor).split('.')[0];
    let decimales = String(valor).split('.')[1];
    let enteros_array = String(enteros).split('');
    let nuevos_enteros = [];
    let contador = 0;
    if (enteros_array.length > 3) {

        for (let i = enteros_array.length - 1; i >= 0; i--) {
            if (contador === 3) {
                nuevos_enteros.unshift(',')
                contador = 0;
            }
            nuevos_enteros.unshift(enteros_array[i]);
            contador++;
        }
        if (!decimales) {
            return ('$' + nuevos_enteros.join('') + '.00');
        } else {
            return ('$' + nuevos_enteros.join('') + '.' + (decimales.length > 0 ? decimales : '00'));
        }
    } else {
        if (!decimales) {
            return ('$' + enteros + '.00');
        } else {
            return ('$' + enteros + '.' + (decimales.length > 0 ? decimales : '00'));
        }
    }

}

// Contiene los endpoints de las apis que se utilizan en el portal
// export const RUTAS = {
//     // el ultio valor es el id de la configuracion
//     obtener_configuracion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     // es necesario reemplazar factura_id con el numero de id de la factura
//     obtner_xml_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }

//ENPOINT PRODUCTIVOS
// [14:38] Oscar Alonso Cespedes Bonilla
// La URL de la API del AutoFacturador de Facto Esmeralda (General) es: http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/test



// Rutas Publicas
// export const RUTAS = {
//     // el ultio valor es el id de la configuracion
//     obtener_configuracion: 'http://200.66.80.197:20011/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://200.66.80.197:20011/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/facturar',
//     // es necesario reemplazar factura_id con el numero de id de la factura
//     obtner_xml_pdf: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://200.66.80.197:20011/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://200.66.80.197:20011/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://200.66.80.197:20011/api/v1/autofacturador/pos/squirrel'
// }




// export const RUTAS = {
//     // el ultio valor es el id de la configuracion
//     obtener_configuracion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     obtener_configuracion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     // es necesario reemplazar factura_id con el numero de id de la factura
//     obtner_xml_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }

/*********************
    Facto Esmeralda
 ********************/
// Facto Esmeralda (General) es: http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/test
// export const RUTAS = {
//     obtener_configuracion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     obtener_configuracion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     obtner_xml_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-factomx-1966305221.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }


/*********************
    Facto Diamante
 ********************/
// Diamante es: http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/test
// export const RUTAS = {
//     obtener_configuracion: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     obtener_configuracion: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     obtner_xml_pdf: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-factoco-1995331077.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }

/*******************
    Facto Platino
 ******************/
// Facto Platino es: http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/test
// export const RUTAS = {
//     obtener_configuracion: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     obtener_configuracion: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     obtner_xml_pdf: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-recepcionposadas-177065149.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }

/****************
    Facto JADE
 ***************/
// Facto JADE es: http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/test

// export const RUTAS = {
//     obtener_configuracion: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/configuracion/',
//     ticket_pos_micros: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/micros',
//     facturar_trasaccion: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/facturar',
//     obtner_xml_pdf: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/factura_id/archivos?',
//     previsualizacion_pdf: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/pdf-preview',
//     enviar_email: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/email',
//     agrupar_concepto: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/agrupar-conceptos',
//     comprimir_archivos: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/facturacion/comprimir',
//     ticket_pms_opera: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pms/opera',
//     ticket_pos_squirrel: 'http://lb-nt-portalclientes-349415291.us-east-1.elb.amazonaws.com/api/v1/autofacturador/pos/squirrel'
// }



export let RUTAS = window.ROUTES;