export const addToCounter = (store, amount) => {
    const counter = store.state.counter + amount;
    store.setState({ counter });
};
export const addDetalleFactura = (store, detalleFactura) => {
    store.setState({ detalleFactura });
};
export const addConfigInicial = (store, configInicial) => {
    store.setState({ configInicial });
};

export const onChange = (store, detalleFactura) => {
    store.setState({ detalleFactura });
};

export const addConfiguracionGeneral = (store, configuracionGeneral) => {
    store.setState({ configuracionGeneral });
}
export const addDocumentoPDF = (store, documento_pdf) => {
    store.setState({ documento_pdf });
}

export const addUrl = (store, url_origen) => {
    store.setState({ url_origen });
};
export const addPreviewPdf = (store, preview_pdf) => {
    store.setState({ preview_pdf });
};
export const addMensaje_notFound = (store, mensaje_notFound) => {
    localStorage.setItem('mensaje_notFound', mensaje_notFound);
    store.setState({ mensaje_notFound });
};

