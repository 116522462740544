/**
 * Verifica si los clientes que acceden pertenecen a un grupo selecto que aun no debe usar la vercion de cfdi 4.0
 * @param identificador Identificador del corporativo.
 */
export const redirecciona33 = (identificador) => {
    console.log(identificador);
    const lista_camino_real = [80, 81, 82, 48, 400, 139, 165]; // Lista de identificadores que deben ser redireccionados del servidor Esmeralda. 
    const redireccionar_33 = lista_camino_real.indexOf(Number(identificador));
    const esmeralda = window.location.href.indexOf('esmeralda');
    if ((redireccionar_33 >= 0) && (esmeralda >= 0)) { // El identificador existe en la lista de identificadores a redireccionar.
        window.location.replace(`https://com-next-frontonline-general.web.app/#/PortalTickets-1.0/micros.xhtml?configuracion=${identificador}`);
    }
}

/**
 * Esta función es para mandar el mensaje a la viste
 * que mostrara el botón de 4.0 o lo ocultara  
 * 
 * Revisa cual es el servidor, y al identificarlo 
 * manda el identificador de Portal y revisa si este
 * se encuentra dentro de la lista y manda mensaje a la vista 
 */
export const vistaBoton40 = identificador => {
    var vista;
    /*  const esmeralda = window.location.href.indexOf('esmeralda');
     if (esmeralda >= 0) { return vista = Esmeralda(identificador) }
     const jade = window.location.href.indexOf('jade');
     if (jade >= 0) { return vista = Jade(identificador) } */
    const diamante = window.location.href.indexOf('diamante');
    if (diamante >= 0) { return vista = Diamante(identificador) }
}

const Esmeralda = identificador => {
    var vista;
    // const lista_portales = [48, 80, 81, 82, 139, 165, 400];  lista de Camino Real
    const lista_portales = [0];
    const dentro_de_lista = lista_portales.indexOf(Number(identificador));
    if (dentro_de_lista >= 0) {
        return vista = false;
    } else {
        return vista = true;
    }
}
const Jade = identificador => {
    var vista;
    // const lista_portales = [89]; lista de Camino Real
    const lista_portales = [0];
    const dentro_de_lista = lista_portales.indexOf(Number(identificador));
    if (dentro_de_lista >= 0) {
        return vista = false;
    } else {
        return vista = true;
    }
}
const Diamante = identificador => {
    var vista;
    const lista_portales = [0];
    const dentro_de_lista = lista_portales.indexOf(Number(identificador));
    if (dentro_de_lista >= 0) {
        return vista = false;
    } else {
        return vista = true;
    }
}