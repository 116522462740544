import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, useEffect, useState } from 'react';
import Loading from './loagin';
import useGlobal from '../store';
import Swal from 'sweetalert2';
import { RUTAS } from './../Servicios/FuncionesGlobales';
import { redirecciona33 } from '../utils/redireccionar33';
import Manual from '../componentes/manual';
const TarjetaMain = lazy(() => import('./../componentes/TarjetaMain'));
const TarjetaMainSquirrel = lazy(() => import('./../componentes/TarjetaMainSquirrel'));
const TarjetaMainOpera = lazy(() => import('./../componentes/TarjetaMainOpera'));
const Detalle = lazy(() => import('./../componentes/ventana'));
const Conceptos = lazy(() => import('./../componentes/Conceptos'));
const Visor = lazy(() => import('./../componentes/visor'));
const NotFound = lazy(() => import('./notFound/index'));
const TarjetaMainDivitsa = lazy(() => import('./../componentes/DivitsaMain'));

const AppMain = () => {
    var rfc = '';
    var referencia = '';
    document.body.classList.add('fondo-default');
    const [globalState, globalActions] = useGlobal();
    const [datosIniciales, setDatosIniciales] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            obtenerDatosIniciales();
        }

        // const { history } = props;
        // window.addEventListener("popstate", () => {
        //     history.go(1);
        // });
        return (() => { isSubscribed = false });
    }, []);


    // componentDidMount() {
    //     const { history } = this.props;
    //     window.addEventListener("popstate", () => {
    //       history.go(1);
    //     });
    //   }

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const obtenerDatosIniciales = () => {
        if (window.location.href.split('&').length > 1) {
            let aux = window.location.href.split('&')[0]
            localStorage.setItem('url_Autoportal', aux.split('#')[1])
        } else {
            localStorage.setItem('url_Autoportal', window.location.href.split('#')[1])
        }
        globalActions.addUrl(window.location.href.split('#')[1]);
        let identificador = getParameterByName('configuration');  // window.location.href.split('=')[1];      
        // redirecciona33(identificador);
        const url = RUTAS.obtener_configuracion + identificador;
        if (identificador) {
            if (!datosIniciales || datosIniciales == {}) {
                fetch(url).then(response => {
                    if (response.status === 400) {
                        return { mensaje: 'NO SE ENCONTRÓ UNA CONFIGURACIÓN PARA EL AUTOFACTURADOR.' }
                    }
                    return response.json();
                })
                    .catch(() => {
                        globalActions.addMensaje_notFound('Verifica el los datos de la url,si el problema persiste por favor contáctanos al +525512058000');
                        window.location.hash = '#/NotFound'
                        window.location.reload();
                    }
                    )
                    .then(data => {
                        if (data && !data.mensaje) {
                            setDatosIniciales(data);
                            globalActions.addConfigInicial(data);
                            // verificaDatosPrellenados();
                            console.log(globalState);
                        } else {
                            globalActions.addMensaje_notFound(data.mensaje);
                            window.location.hash = '#/NotFound'
                            window.location.reload();
                            // return <Redirect to='/NotFound' />
                        }
                    });
            }
        } else {
            globalActions.addConfigInicial({});
            setDatosIniciales({});
        }
    }




    if (datosIniciales && datosIniciales !== {}) {
        return (
            <Fragment>
                {
                    globalState.configInicial && globalState.configInicial.leyendas ?
                        globalState.configInicial.leyendas.map((element) => {

                            if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
                            {
                                if (element.tipo === 'MSG_ALERTA' && showAlert === false) {
                                    alert(element.leyenda);
                                    setShowAlert(true);
                                }
                            } else {
                                if (element.tipo === 'MSG_ALERTA' && showAlert === false) {
                                    Swal.fire({
                                        title: element.leyenda,
                                    })
                                    setShowAlert(true);
                                }
                            }
                        })
                        : null
                }

                {/* Rutas Micros */}
                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/micros:configuracion" render={() => (<TarjetaMain cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/micros:configuracion/:clave_facto" render={() => (<TarjetaMain cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                {/* Rutas Opera */}

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/opera:configuracion" render={() => (<TarjetaMainOpera cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>
                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/opera:configuracion/:clave_facto" render={() => (<TarjetaMainOpera cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                {/* Rutas Squirrel */}

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/squirrel:configuracion" render={() => (<TarjetaMainSquirrel cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                {/* Rutas Divitsa */}

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/PortalTickets-1.0/divitsa:configuracion" render={() => (<TarjetaMainDivitsa cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/detalle" component={Detalle} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/loading" component={Loading} />
                </Suspense>
                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/visor" component={Visor} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/conceptos" component={Conceptos} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/NotFound" render={() => (<NotFound cambioURL={obtenerDatosIniciales} />)} />
                </Suspense>

                <Suspense fallback={
                    <Loading />
                }>
                    <Route path="/manual" component={Manual} />
                </Suspense>

                <Route exact path="/" render={() => (
                    // <Redirect to="/inicio/spm?configuracion=80" />
                    // <Redirect to="/PortalTickets-1.0/micros" />
                    <Redirect to="/NotFound" cambioURL />
                )} />
            </Fragment>
        )
    } else {
        return <Loading />
    }

};

export default AppMain;