import React, { Fragment } from 'react';
import './manual-estilos.css';
import { pdf } from '../../utils/pdf';



export default function Manual() {
    /*  const dataURItoBlob = (dataURI) => {
         console.log(dataURI);
         const byteString = window.atob(dataURI);
         const arrayBuffer = new ArrayBuffer(byteString.length);
         const int8Array = new Uint8Array(arrayBuffer);
         for (let i = 0; i < byteString.length; i++) {
             int8Array[i] = byteString.charCodeAt(i);
         }
         const blob = new Blob([int8Array], { type: 'application/pdf' });
         return blob;
     }
 
     const blob = dataURItoBlob(pdf);
     const url = URL.createObjectURL(blob); */
    window.location.href = window.ROUTES.url_pdf;
    // window.open(url, '_blank');
    return (
        <Fragment>
            {/* <div id="wrap">
                <iframe  id="frame" height="200px" src={'data:application/pdf;base64,' + pdf}  ></iframe>
            </div>                 */}
        </Fragment>
    );
}
